import BaseBean from "@/utils/BaseBean";

export interface IPiTemplateListDataObj {
    utilInst:PiTemplateListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class PiTemplateListUtil extends BaseBean{
    public dataObj:IPiTemplateListDataObj

    constructor(proxy:any,dataObj:IPiTemplateListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}