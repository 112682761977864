import PiTemplateCard from './PiTemplateCard.vue';
import {reactive, toRefs, defineComponent,onBeforeMount, onMounted, getCurrentInstance} from 'vue';
import PiTemplateListUtil,{IPiTemplateListDataObj} from './piTemplateListUtil';
import language from './piTemplateLanguage'
import T001Card from "@/views/project/order/order/piTemplate/t001/T001Card.vue";
import T003Card from "@/views/project/order/order/piTemplate/t003/T003Card.vue";
export default defineComponent ({
    name: 'PiTemplateList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IPiTemplateListDataObj=reactive<IPiTemplateListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'PI模板信息',
                queryParam: {},
                modelComp: PiTemplateCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/piTemplate/pageData')
            },
            otherParams:{
                dialogVisible:false
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new PiTemplateListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------

        const formatPageInfo=(options:any)=>{
            //找到需要格式化的列
            //options.pageInfo.columns.forEach((item:any)=>{
            //    if(item.prop=='F_DY_FLAG')item.formatter=utils.UtilPub.formatterYesNoStatus;
            //})
            return options;
        }
        //查看
        const viewTemplate=async (row:any)=>{
            // dataObj.otherParams.dialogVisible=true;
            let modelComp;
            switch (row['F_CODE']) {
                case '001':modelComp=T001Card;break;
                case '002':break;
                case '003':modelComp=T003Card;break;
            }
            if(modelComp){
                await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:row.F_ID,modelComp:modelComp,ownerComp:dataObj.pageListRef,from:'PiTemplateList',
                    title: row['F_NAME'],contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true,code:row['F_CODE'],templateId:row['F_ID']})
            }
        }
        //测试用  可删除
        const print1=()=>{
            let dialogContent:any = (document.getElementById('my-dialog') as any).innerHTML;
            let printWindow :any= window.open('', '_blank');
            printWindow.document.open();
            printWindow.document.write('<html><head><title>打印</title></head><body>');
            printWindow.document.write(dialogContent);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        }
        return{
            ...toRefs(dataObj),formatPageInfo,viewTemplate,print1
        }
    }
});