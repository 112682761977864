export default {
    zh:{
        piTemplate:{
            queryParam:{
                name:'名称',
                name_placeholder:'请输入名称',
                code:'编码',
                code_placeholder:'请输入编码',
            },
            columns:{
                F_NAME:'名称',
                F_CODE:'编码',
            },
            form:{
                code:'编码',
                code_placeholder:'请输入编码',
                code_rule: '请输入编码',
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule: '请输入名称',
                address:'地址',
                address_placeholder:'请输入地址',
            },
        }
    },
    en:{
        piTemplate:{
            queryParam:{
                name:'名称',
                name_placeholder:'请输入名称',
                code:'编码',
                code_placeholder:'请输入编码',
            },
            columns:{
                F_NAME:'名称',
                F_CODE:'编码',
            },
            form:{
                code:'编码',
                code_placeholder:'请输入编码',
                code_rule: '请输入编码',
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule: '请输入名称',
                address:'地址',
                address_placeholder:'请输入地址',
            },
        }
    }
}